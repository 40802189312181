.App {
  /* text-align: center; */
  position: absolute;
        top: 0;
        width: 100%;
}

.App-logo {
  height: 40vmin;
}

.App-header1 {
  background-color: rgb(87, 110, 53);
  background-image: url("http://getwallpapers.com/wallpaper/full/2/7/e/1067148-full-screen-wallpapers-desktop-1920x1080-for-phone.jpg#.XYWUaT5x_I4.link");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
 /* color: white; */
}

.pcolor{
  color: white;
}

.App-header2 {
  background-color: rgb(87, 110, 53);
  background-image: url("http://getwallpapers.com/wallpaper/full/3/7/5/1067158-new-full-screen-wallpapers-desktop-1920x1200-for-mac.jpg#.XYZdxxU6z60.link");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

.App-header3 {
  background-color: rgb(87, 110, 53);
  background-image: url("http://getwallpapers.com/wallpaper/full/3/d/d/1067200-gorgerous-full-screen-wallpapers-desktop-2048x1365-for-lockscreen.jpg#.XYZdxzkM1WY.link");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.card {
  background-color: rgb(17, 17, 16);
  border: 0;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(239, 240, 243, 0.473),
    0 2px 4px rgba(250, 248, 248, 0.24);
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;
  justify-content: center;
  display: flex;
}

.card2 {
  background-color: rgb(17, 17, 16);
  border: 0;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(239, 240, 243, 0.473),
    0 2px 4px rgba(250, 248, 248, 0.24);
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;
  justify-content: center;
  display: flex;
}